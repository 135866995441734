import { Link } from '@remix-run/react'
import cx from 'classnames'
import type { LegacyRef, MouseEventHandler, ReactNode, Ref } from 'react'
import { forwardRef } from 'react'

import type { Intent } from '~/services/theme-service'
import { getIntentInteractive } from '~/services/theme-service'
import Spinner from '~/components/Spinner'

export interface ButtonProps {
  text: ReactNode
  /**
   * If provided, renders as a Link
   */
  to?: string
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  intent?: Intent
  size?: 'xs' | 'sm' | 'base' | 'lg'
  isLoading?: boolean
  isDisabled?: boolean
  /**
   * If true && href is provided, renders as a normal anchor with blank target
   */
  newWindow?: boolean
  href?: string
  className?: string
  name?: string
  value?: string
  download?: string
  children?: React.ReactNode
}

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      to,
      href,
      onClick,
      intent,
      size = 'base',
      isLoading = false,
      isDisabled = false,
      newWindow = false,
      className,
      name,
      value,
      download,
      children
    },
    ref
  ) => {
    const resolvedClassName = cx(
      'font-copy font-bold rounded-full transition-all duration-100 text-center',
      isDisabled && 'pointer-events-none bg-light text-mid cursor-not-allowed',
      size === 'xs' && 'text-xs px-2 py-0.5',
      size === 'sm' && 'text-sm px-4 ppy-0.5',
      size === 'base' && 'text-base px-6 py-1',
      size === 'lg' && 'text-lg px-8 py-2',
      !isDisabled && getIntentInteractive(intent),
      className
    )

    return (
      <>
        {href && (
          <a
            href={href}
            ref={ref as LegacyRef<HTMLAnchorElement>}
            target={newWindow ? '_blank' : undefined}
            rel={newWindow ? 'noreferrer nofollow' : undefined}
            className={resolvedClassName}
            download={download}
            onClick={onClick}>
            {isLoading && (
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                <Spinner d={4} className="zinc-700 " />
              </div>
            )}
            <span className={cx(isLoading && 'invisible')}>{text}</span>
            {children && children}
          </a>
        )}
        {to && (
          <Link
            to={to}
            ref={ref as Ref<HTMLAnchorElement>}
            target={newWindow ? '_blank' : undefined}
            rel={newWindow ? 'noreferrer nofollow' : undefined}
            className={`relative ${resolvedClassName}`}
            prefetch="intent"
            onClick={onClick}>
            {isLoading && (
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <Spinner d={4} className="zinc-700 " />
              </div>
            )}
            <span className={cx(isLoading && 'invisible')}>{text}</span>
            {children && children}
          </Link>
        )}
        {!to && !href && (
          <button
            type={onClick ? 'button' : 'submit'}
            ref={ref as LegacyRef<HTMLButtonElement>}
            onClick={onClick}
            className={`relative ${resolvedClassName}`}
            disabled={isDisabled}
            name={name}
            value={value}>
            {isLoading && (
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <Spinner d={4} className="zinc-700 " />
              </div>
            )}
            <span className={cx(isLoading && 'invisible')}>{text}</span>
            {children && children}
          </button>
        )}
      </>
    )
  }
)

Button.displayName = 'Button'

export default Button
